<template>
  <div class="ServiceMalladmin">
    <!-- 头部 -->
    <div class="ServiceMalladmin-header">
      <!-- 搜索 -->
      <div class="search">
        <van-search v-model="search" placeholder="输入搜索内容" shape="round" @search="handsearch" />
      </div>
      <!-- banner -->
      <div class="banner"></div>
    </div>

    <!-- 导航 -->
    <nav>
      <van-grid square :column-num="5" :border="false">
        <van-grid-item @click="jumpnav(item)" v-for="(item,index) in navTab" :key="index">
          <img :src="item.imgSrc" alt="" />
          <p>{{item.title}}</p>
        </van-grid-item>
      </van-grid>
    </nav>

    <!-- 内容 -->
    <van-list
        v-model="loading"
        :finished="finished"
        @load="getServerList"
    >
    <div class="ServiceMalladmin-content">
      <div class="content-item" v-for="item in this.list" :key="item.id" :class="`${item.id == '2' ? 'top':''}`">
         <!-- <v-touch @swipeleft="left" @swiperight="right"  tag="div" v-if="item.id=='1'"> -->
           
              <div class="item-title clearfix">
                <div class="title-left">
                  <span><img :src="`${item.id == '1' ? hot : line}`" alt=""  :class="`${item.id == '1' ? 'hot':'line'}`"/></span>
                  {{ item.columnName }}
                </div>
                <div class="title-right" @click="more(item.id,item.columnName)">更多>></div>
              </div>

            <div :class="item.id ==2?'overflow':item.id ==1?'box':'surplus'">
              <div class="item-substance clearfix" :class="`${item.id == '1' ? ' special-box':''}`">
                <div class="substance-item" :class="`${item.id == '1' ? 'special':'other'}`"  v-if="item.id ==2" @click="jumpvip">
                    <div class="images">
                      <img src="../assets/image/mall-vip.png" alt="" />
                    </div>
                    <div class="details">
                      <div class="details-name">VIP/SVIP会员</div>
                      <div class="details-suggest">
                        8元/连续包月
                      </div>
                      <div class="details-prices"><span>￥</span>8.0<span class="colors"> 起</span></div>
                      <div class="buy" v-if="item.id=='1'" >购买</div>
                    </div>
                </div>
              
                <!-- <div class="substance-item" :class="`${item.id == '1' ? 'special':'other'}`" v-if="item.id ==2" @click="jumpvip">
                    <div class="images">
                      <img src="../assets/image/mall-svip.png" alt="" />
                    </div>
                    <div class="details">
                      <div class="details-name">SVIP会员</div>
                      <div class="details-suggest">
                        15元/连续包月
                      </div>
                      <div class="details-prices"><span>￥</span>15.0<span class="colors"> 起</span></div>
                      <div class="buy" v-if="item.id=='1'">购买</div>
                    </div>
                </div> -->
                
                 <div class="substance-item" :class="`${item.id == '1' ? 'special':'other'}`" v-if="item.id ==2" @click="jump">
                    <div class="images">
                      <img src="../assets/image/mall-text.png" alt="" />
                    </div>
                    <div class="details">
                      <div class="details-name">字数包</div>
                      <div class="details-suggest">
                        以万字为单位
                      </div>
                      <div class="details-prices"><span>￥</span>0.98<span class="colors"> /万字</span></div>
                      <div class="buy" v-if="item.id=='1'">购买</div>
                    </div>
                </div>

                <div class="substance-item"  v-for="val in item.productList"  :key="val.id"   :class="`${item.id == '1' ? 'special':'other'}`" @click="jump(val.id)">
                  <template v-if="item.id!==2" >
                    <div class="images">
                      <img :src="`${https}${val.picList[0].picPath}`" alt="" />
                    </div>
                    <div class="details">
                      <div class="details-name">{{ val.productName }}</div>
                      <div class="details-suggest">
                        {{val.authorName}}
                      </div>
                      <div class="details-prices">
                        <span>￥</span>{{val.specialPrices==null?val.productPrices:val.specialPrices}}<span></span>
                        <span class="last" v-show="val.specialPrices">￥{{val.productPrices}}</span>
                      </div>
                      <div class="buy" v-if="item.id=='1'" v-on:click.stop="onStop(val)">购买</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          <!-- </v-touch> -->
      </div>
      
      <!-- 购物车 -->
      <div class="shoppingcar" @click="shoppingcar">

      </div>
      
      <div class="kong"></div>
      </div>
    </van-list>
  </div>
</template>

<script>
// vant组件
import Vue from "vue";
import { Search } from "vant";
import { Grid, GridItem } from "vant";
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Search);

import { serverMallList,payment } from "@api/servicemall.js";
import qs from "qs";
export default {
  data () {
    return {
      search: "",
      list: [],
      hot: "",
      line: "",
      https:'https://p.writemall.com/mallFile/product/',
      loading : false,
      finished : false,
      pageNo: 0,
      navTab:[
        {
          title:"重点推荐",
          imgSrc:require('../assets/image/mall-nav1.png'),
          id:17,
        },
         {
          title:"专业数据",
          imgSrc:require('../assets/image/mall-nav2.png'),
          id:3,
        },
         {
          title:"实务数据",
          imgSrc:require('../assets/image/mall-nav3.png'),
          id:13,
        },
         {
          title:"素材数据",
          imgSrc:require('../assets/image/mall-nav4.png'),
          id:16,
        },
         {
          title:"特色排行",
          imgSrc:require('../assets/image/mall-nav5.png'),
          id:18,
        },
      ],
    };
    
  },
  methods: {
    //跳转vip
    jumpvip(){
       this.$router.push({
         name:'ServiceMallDef'
       })
    },
    //顶部导航跳转
    jumpnav(item){
       this.$router.push({
         name:'more',
         query:{
           id:item.id,
           title:item.title
         }
       })
    },
    //跳转更多
    more(id,name){
       this.$router.push({
         name:'more',
         query:{
           id:id,
           title:name
         }
       })
    },
    //路径转换
    imgSrc () {
      let hotsrc = 'mall-hot.png'
      let linesrc = 'mall-title-line.png'
      this.hot = require('../assets/image/' + hotsrc)
      this.line = require('../assets/image/' + linesrc)
    },
    //请求数据
    async getServerList () {
       this.$toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });
      const ServerList = {
        productName:this.search
      };
      //发送请求
      const result = await serverMallList(qs.stringify(ServerList));
      if (result.code === 0) {
        if(result.data.length == 0){
          this.loading = false;
          this.finished = true;
        }else{
          this.$toast.clear();
          this.finished = true;
          this.loading = false;
          this.list = result.data
        }
      }else{
        this.$toast(result.data.msg); 
      }
    },
    jump(id){
      let numReg = /^[0-9]*$/
      if(numReg.test(id)){
          this.$router.push({
            name:'details',
            query:{
              id:id
            }
          })
      }else{
        id=16
        this.$router.push({
            name:'details',
            query:{
              id:id
            }
        })
      }
    },
    shoppingcar(){
      this.$router.push({
        name:'shoppingcar'
      })
    },
    //搜索
    handsearch(value){
       if(value == ""){
          this.$toast("内容不能为空!");
          return;
       }else{
          sessionStorage.setItem("value",value)
          this.$router.push({
            name:'Search',
          })
       }
       
    },
    //购买
    async onStop(item){
         const data = [
         {
            productId:item.id,
            productNum:1
         },
       ]
       const res = await payment(data)
       if(res.code === 0){
         sessionStorage.setItem("oid",res.data.oid)
         this.$router.push({
           name:'buynow'
         })
       }
    },
  },
  created () {
    this.imgSrc();
    this.getServerList();

  },
}
</script>

<style lang="scss" scoped>
// .top{
//   margin-top: 135px;
// }
.ServiceMalladmin {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: PingFang SC;
  padding-bottom: 50px;
  .NavigationBar-searchbox-code{
    display: none;
  }
  &-header {
    position: relative;
    height: 173px;
    background-image: url('~@image/mall-headerbgc.png');
    background-repeat: no-repeat;
    background-size: 418px 182px;
    background-position: -17px -10px;
    .search {
      margin-top: 25px;
      ::v-deep .van-search {
        background-color: transparent;
        .van-field__control {
          font-size: 12px;
        }
      }
    }
    .banner {
      width: 345px;
      height: 152px;
      background-image: url('~@image/mall-banner.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 8px;
      box-shadow: 0px 9px 26px 1px rgba(33, 39, 70, 0.08);
    }
  }
  nav {
    margin-top: 108px;
    img {
      width: 35px;
    }
    p {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #1d1d1d;
    }
  }
  &-content {
    width: 100%;
    .content-item {
      width: 100%;
      .item-title {
        margin-top: 25px;
        .title-left {
          float: left;
          margin-left: 34px;
          font-size: 15px;
          font-weight: bold;
          color: #1d1d1d;
          position: relative;
          span{
            position: absolute;
            left: -20px;
            top: 3px;
            height: 15px;
            width: 15px;
            .hot{
              width: 100%;
              height: 100%;
              
            }
            .line{
              width: 2px;
              margin-left: 12px;
              height: 13px;
            }
          }
        }
        .title-right {
          float: right;
          font-size: 13px;
          font-weight: 500;
          color: #999999;
          margin-right: 15px;
        }
      }
    }
    .box{
      overflow-x: auto;
      display: -webkit-box;
    }
    .overflow{
      display: flex;
      justify-content: flex-start;
      margin-left: 16px;
    }
    .surplus{
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 20px;
    }
    .special-box{
      margin-right: 20px;
      .special{
              float: left;
              margin-top: 15px;
              margin-left: 15px;
              width: 220px;
              box-shadow: 0px 9px 26px 1px rgba(33, 39, 70, 0.08);
              border-radius: 10px;
              height: 105px;
              margin-bottom: 15px;
              .images{
                float: left;
                width: 41px;
                height: 58px;
                margin-left: 21px;
                margin-top: 21px;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
              .details{
                 float: left;
                 margin-left: 21px;
                 margin-top: 21px;
                 width: 135px;
                 position: relative;
                 .details-name{
                   font-size: 14px;
                   font-weight: bold;
                   color: #333333;
                 }
                 .details-suggest{
                    margin-top: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #4587FF;
                 }
                 .details-prices{
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    width: 100%;
                    color: #EB441E;
                      .last{
                        font-family: PingFang SC;
                        font-weight: 500;
                        text-decoration: line-through;
                        color: #CCCCCC;
                        font-size: 12px;
                      }
                 }
                 .buy{
                   position: absolute;
                   right: 3px;
                   bottom: 0px;
                   width: 47px;
                   height: 21px;
                   text-align: center;
                   line-height: 21px;
                   background: url('~@image/mall-buy-bgc.png') no-repeat;
                   background-size: 100% 100%;
                   color: #fff;
                 }
              }
      }
    }
    .item-substance{
      display: flex;
      .other{
        flex: 1;
        margin-left: 14px;
        margin-top: 20px;
        text-align: center;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        .images{
          width: 80px;
          height: 80px;
          background: #fafbfd;
          border-radius: 10px;
          img{
            width: 100%;
            height:100%;
            border-radius: 10px;
            margin-top: 20px;
            transform: translateY(-23px);
          }
        }
        .details{
            margin-top: 10px;
            text-align: left;
            width: 80px;
            .details-name{
              font-size: 15px;
              font-weight: bold;
              color: #333333;
              overflow:hidden; /*溢出的部分隐藏*/
              white-space: nowrap; /*文本不换行*/
              text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
            }
            .details-suggest{
              font-size: 12px;
              font-weight: 500;
              color: #999999;
              overflow:hidden; /*溢出的部分隐藏*/
              white-space: nowrap; /*文本不换行*/
              text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
              margin-top: 8px;
            }
            .details-prices{
               font-size: 12px;
               margin-top: 8px;
               overflow:hidden; /*溢出的部分隐藏*/
               white-space: nowrap; /*文本不换行*/
               text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
               color: #EB441E;
                  .last{
                    font-family: PingFang SC;
                    font-weight: 500;
                    text-decoration: line-through;
                    color: #CCCCCC;
                    font-size: 12px;
                  }
              .colors{
                color: #cccccc;
              }
            }
        }
      }
    }
  }
  .shoppingcar{
    position: fixed;
    right: 13px;
    bottom: 95px;
    width: 54px;
    height: 54px;
    background: url('~@image/mall-car.png') no-repeat;
    background-size: 100% 100%;
  }
  .kong{
    height: 30px;
  }
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
}
</style>
